define("discourse/plugins/discourse-push-notifications/discourse/components/push-notification-config", ["exports", "discourse-common/utils/decorators", "discourse/lib/push-notifications"], function (_exports, _decorators, _pushNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_obj = {
    showSetting() {
      return this.siteSettings.desktop_push_notifications_enabled;
    },

    actions: {
      change(enable) {
        if (enable) {
          this.currentUser.set("custom_fields.discourse_push_notifications_prefer_push", true);

          _pushNotifications.keyValueStore.setItem("prefer_push", "true");
        } else {
          this.currentUser.set("custom_fields.discourse_push_notifications_prefer_push", false);

          _pushNotifications.keyValueStore.setItem("prefer_push", "");
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "showSetting", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showSetting"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-push-notifications/discourse/initializers/setup-push-notifications", ["exports", "discourse/lib/plugin-api", "discourse/lib/push-notifications"], function (_exports, _pluginApi, _pushNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "setup-push-notifications",

    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        const siteSettings = container.lookup("site-settings:main");

        if (!Ember.testing && api.getCurrentUser()) {
          if (siteSettings.desktop_push_notifications_enabled) {
            //open class up, add property for saving on notifications
            api.modifyClass("controller:preferences/notifications", {
              pluginId: "discourse-push-notifications",
              saveAttrNames: ["muted_usernames", "new_topic_duration_minutes", "auto_track_topics_after_msecs", "notification_level_when_replying", "like_notification_frequency", "allow_private_messages", "custom_fields"]
            });
            api.modifyClass("component:desktop-notification-config", {
              pluginId: "discourse-push-notifications",

              isPushNotificationsPreferred() {
                if (!this.site.mobileView && !_pushNotifications.keyValueStore.getItem("prefer_push")) {
                  return false;
                }

                return (0, _pushNotifications.isPushNotificationsSupported)(this.site.mobileView);
              }

            }); // add key, prefer push

            if (api.getCurrentUser().custom_fields["discourse_push_notifications_prefer_push"]) {
              _pushNotifications.keyValueStore.setItem("prefer_push", "true");
            } else {
              _pushNotifications.keyValueStore.setItem("prefer_push", "");
            }
          } else {
            _pushNotifications.keyValueStore.setItem("prefer_push", "");
          }
        }
      });
    }

  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/push-notification-config"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n    \"],[10,\"input\"],[15,\"checked\",[33,2,[\"custom_fields\",\"discourse_push_notifications_prefer_push\"]]],[15,\"onclick\",[28,[37,3],[[30,0],\"change\"],[[\"value\"],[\"target.checked\"]]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n    \"],[1,[28,[35,4],[\"discourse_push_notifications.note\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showSetting\",\"currentUser\",\"action\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/components/push-notification-config","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/user-preferences-desktop-notifications/user-push-notification-input"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[34,0]],[1,\"\\n\"]],[],false,[\"push-notification-config\"]]","moduleName":"javascripts/discourse/templates/connectors/user-preferences-desktop-notifications/user-push-notification-input","isStrictMode":false});

